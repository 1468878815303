import React from "react";
import styles from "./ModalView.module.css";

const ModalView = ({ setShow = () => {}, children, background }) => {
  return (
    <div onClick={() => setShow(false)} className={styles.global_container}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.container + ` ${background}`}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalView;
