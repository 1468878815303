import { fetchPost } from '../../utils/fetchApi';
import { authTypes } from '../types/authTypes';
import { url_api } from '../../config/config';
import { sortFilesByDate } from '../../utils/funtionsFiles';

export const login = (username, password) => async dispatch => {

  dispatch(startLoading());
  dispatch(setError(null));

  const url = `${url_api}/agent/login`;
  const body = { username, password };

  const response = await fetchPost(url, body);
  const { ok } = response;

  if (!ok) {
    let error = response['error'];
    let message = null;

    if (!error) ({ error, message } = await response.json());
    const timeError = error ? error : response.error;
    dispatch(setError(error || message || timeError));
  } else {
    const { agent, tokens } = await response.json();

    localStorage.setItem('token', tokens.access.token);
    localStorage.setItem('refreshToken', tokens.refresh.token);

    const bodyTkn = { token: tokens.access.token, refreshToken: tokens.refresh.token }

    const info = {
      type: authTypes.LOGIN,
      payload: { agent, tokens: bodyTkn }
    }

    dispatch(info);
    dispatch(removeError());
  }

  dispatch(stopLoading());
};

export const logout = () => async dispatch => {
  const refreshToken = localStorage.getItem('refreshToken');
  const url = `${url_api}/auth/logout`;
  const body = { refreshToken };

  if (refreshToken) await fetchPost(url, body);

  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  dispatch({ type: authTypes.LOGOUT });
}

export const refreshToken = async () => {

  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) return logout();

  const url = `${url_api}/auth/refresh-tokens`;
  const body = { refreshToken }

  const response = await fetchPost(url, body);

  const { access, refresh } = await response.json();
  localStorage.setItem('token', access.token);
  localStorage.setItem('refreshToken', refresh.token);
}

export const setError = (error) => ({
  type: authTypes.SET_ERROR,
  payload: {
    error
  }
});

export const removeError = () => ({
  type: authTypes.REMOVE_ERROR
});

export const startLoading = () => ({
  type: authTypes.START_LOADING
});

export const stopLoading = () => ({
  type: authTypes.STOP_LOADING,
});

export const getUserFiles = (typeDoc, identification, initDate, endDate) => async dispatch => {
  dispatch(startLoading());
  dispatch(removeError());

  const url = `${url_api}/agent/user-files`;
  const body = { identification, typeDoc, initDate, endDate };

  const response = await fetchPost(url, body);
  const { ok } = response;

  if (!ok) {
    let error = response['error'];
    let message = null;

    if (!error) ({ error, message } = await response.json());
    const timeError = error ? error : response.error;
    dispatch(setError(error || message || timeError));
  } else {
    const { lista, message } = await response.json();
    let ordered = [];
    if (lista.length)
      ordered = sortFilesByDate(lista);

    const info = {
      type: authTypes.GET_USER_FILES,
      payload: { userFiles: ordered }
    }

    dispatch(info);

    if (message) dispatch(setError(message));
  }

  dispatch(stopLoading());
};

export const selectDoc = (urlBlob) => ({
  type: authTypes.SELECT_FILE,
  payload: {
    fileSelected: urlBlob,
  }
});

export const ClearDoc = () => ({
  type: authTypes.CLEAR_FILE,
});
