import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewFileView } from './ViewFileView';

export const ViewFileContainer = () => {

  const { fileSelected } = useSelector(state => state.auth);
  const [urlFile, setUrlFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setUrlFile(null);
    }
  }, []);

  useEffect(() => {
    if (fileSelected) setUrlFile(fileSelected);
  }, [fileSelected]);

  const goBack = () => {
    navigate(-1);
  }

  return (
    <ViewFileView
      goBack={goBack}
      urlDoc={urlFile}
    />
  )
}
