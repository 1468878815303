export const authTypes = {
  LOGIN: '[auth] login',
  LOGOUT: '[auth] logout',
  SET_ERROR: '[auth] set error',
  REMOVE_ERROR: '[auth] remove error',
  START_LOADING: '[auth] start loading',
  STOP_LOADING: '[auth] stop loading',
  REFRESH_TOKEN: '[auth] refresh token',
  GET_USER_FILES: '[auth] get user files',
  SELECT_FILE: '[auth] select file',
  CLEAR_FILE: '[auth] clear file',
}
