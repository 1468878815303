import React from 'react';

export const ViewFileView = ({ goBack, urlDoc }) => {
  return (
    <div className="container main-background text-center vh-100 d-flex flex-column justify-content-center align-items-center ">
      <div className="scroll-hidden pb-6">
        {/* {urlDoc && <iframe
        width='600'
        height='700'
        title='view'
        src={urlDoc}
      >
      </iframe>} */}
      <div className="space"></div>

        {urlDoc && (
          <img
            className="border border-primary p-2 rounded"
            width="800"
            height="900"
            title="view"
            src={urlDoc}
            alt="document"
          />
        )}

        <div className="space text-start d-flex align-items-center clickable">
          <img src="assets/btn_atras.png" onClick={goBack} alt="Back" />
        </div>
      </div>
    </div>
  );
};
