import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFiles, logout, selectDoc } from '../../store/actions/authActions';
import { createUrlBlobFromBase64, downloadFile } from '../../utils/funtionsFiles';
import { SearchFilesView } from './SearchFilesView';

export const SearchFilesContainer = () => {
  const selectDocs = ['', 'CC', 'CE', 'TI', 'NIP', 'NIT', 'PAP', 'RC'];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userFiles, loading, error } = useSelector((state) => state.auth);

  const [formValues, setFormValues] = useState({ typeDoc: '', identification: '', initDate: '', endDate: '' });
  const [arrayFiles, setArrayFiles] = useState([]);

  useEffect(() => {
    setArrayFiles(userFiles);
  }, [userFiles]);

  const handlerFormValues = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    setArrayFiles([]);
    dispatch(getUserFiles(formValues.typeDoc, formValues.identification, formValues.initDate, formValues.endDate));
  };

  const handlerViewDoc = async (file64) => {
    const urlBlob = await createUrlBlobFromBase64(file64);
    // TODO dispatch file
    dispatch(selectDoc(urlBlob));
    navigate('/view');
  };

  const handlerDownDoc = async (file64, name) => {
    const urlBlob = await createUrlBlobFromBase64(file64);
    downloadFile(urlBlob, name);
  };

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <SearchFilesView
      selectDocs={selectDocs}
      setFormValues={handlerFormValues}
      formValues={formValues}
      onSubmit={handlerSubmit}
      files={arrayFiles}
      loading={loading}
      error={error}
      viewDoc={handlerViewDoc}
      downDoc={handlerDownDoc}
      logOut={logOut}
    />
  );
};
