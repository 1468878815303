import { Route, Routes } from "react-router-dom";
import { SearchFilesContainer } from "../components/filesExplorer/SearchFilesContainer";
import { ViewFileContainer } from "../components/viewFile/ViewFileContainer";

export const MenuRoutes = () => {
  return (
    <Routes>
      <Route path="search" element={<SearchFilesContainer />} />
      <Route path='view' element={<ViewFileContainer />} />
    </Routes>
  )
}
