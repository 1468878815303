import React from 'react'

export const Button = ({ children, onClick, className }) => {
    return (
        <button
            onClick={onClick}
            type="button" className={"btn btn-primary " + className}
        >
            {children}
        </button>
    )
}
