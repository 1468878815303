import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
  const { tokens } = useSelector(state => state.auth);
  const token = tokens ? tokens.token : null;

  return (
    token ? children : <Navigate to='/' />
  )
}
