import fetchIntercept from 'fetch-intercept';
import { refreshToken } from '../store/actions/authActions';

export const fetchPost = async (url, bodyrequest, contentType = 'application/json') => {

  const body = JSON.stringify(bodyrequest);
  const token = `Bearer ${localStorage.getItem('token')}`;

  const header = new Headers();

  !!token && header.append('Authorization', token);
  header.append("Content-Type", contentType);

  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: header,
    body,
  }

  try {
    const response = await fetch(url, requestOptions);
    return response;

  } catch (err) {
    const error = { error: err.message || err.error, ok: false };
    return error;
  }
}

export const fetchPostfile = async (url, bodyrequest) => {

  // const body = JSON.stringify(bodyrequest);
  const token = `Bearer ${localStorage.getItem('token')}`;

  const header = new Headers();

  !!token && header.append('Authorization', token);
  // header.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: header,
    body: bodyrequest,
  }

  try {
    const response = await fetch(url, requestOptions);
    return response;

  } catch (err) {
    const error = { error: err.message || err.error };
    return error;
  }
}

export const fetchGet = async (url, params) => {

  try {

    const token = localStorage.getItem('token');
    const urrFetch = new URL(url);
    const header = new Headers();

    for (let param in params) { urrFetch.searchParams.append(param, params[param]) };

    !!token && header.append('Authorization', `Bearer ${token}`);
    header.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: header
    }

    const response = await fetch(urrFetch, requestOptions);
    return response;

  } catch (err) {
    const error = { error: err.message || err.error };
    return error;
  }

}

fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    console.log("error", error);

    return Promise.reject(error);
  },

  response: async function (response) {
    // Modify the reponse object
    const { status } = response;

    if (status === 401) {
      const { message } = response.json();
      if (message !== 'Please authenticate') await refreshToken();
    }

    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    console.log("reserror", error);

    return Promise.reject(error);
  }
});
