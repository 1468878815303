import React from 'react'

export const Submit = ({ value, className }) => {
    return (
        <input
            value={value}
            className={'btn btn-primary ' + className}
            type='submit'
        />
    )
}
