import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { MenuRoutes } from "./MenuRoutes";
import { PrivateRoute } from "./PrivateRouter";
import { LoginContainer } from "../components/loginAgent/LoginContainer";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginContainer/> } />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <MenuRoutes />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
