import React from 'react';
import { Input } from '../basics/input/Input';
import { Submit } from '../basics/buttons/Submit';
import { Button } from '../basics/buttons/Button';
import ModalContainer from '../basics/modal/ModalContainer';
import { Spinner } from '../basics/Spinner';

export const LoginView = ({ onSubmit, username, password, setFormValues, showModal, setShowModal, loading, error }) => {
  return (
    <div className="container-flex login-backgroud vh-100 pb-6">
      <ModalContainer show={showModal} setShow={setShowModal} background="msg-backgroud">
        <div className="container text-center vh-100 grid-login">
          <div className='child-grid-login'>
            <img src="assets/logo_coomevaMP.png" alt="logo" className="logo-mp" />
          </div>

          <div className='child-grid-login'>
            {loading && (
              <div>
                <h1 className='font-bold'>Espere</h1>
                <Spinner />
              </div>
            )}
            {error && (
              <div>
                {/* <h1>error</h1> */}
                <h1 className='font-bold'>{error}</h1>
              </div>
            )}
            <Button className="m-5" onClick={() => setShowModal(false)}>
              Cerrar
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="text-center container scroll-hidden container-center">
        {/* <div className="space"></div> */}
        <img src="assets/logo_coomevaMP.png" alt="" className=" pt-5" />

        {/* <div className="space"></div> */}

        <h1 className="title-white">¡Hola!</h1>
        <form className="card-shadow p-3 bg-white text-center max-container" onSubmit={onSubmit}>
          <Input placeholder="username" onChange={setFormValues} value={username} name="username" />
          <Input placeholder="password" onChange={setFormValues} value={password} name="password" type="password" />
          <Submit value="Aceptar" />
        </form>
      </div>
    </div>
  );
};
