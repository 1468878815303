import React, { useEffect } from 'react'
import { useState } from 'react';
import { LoginView } from './LoginView';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import './loginStyles.css';

export const LoginContainer = () => {

  const { error, loading, agent } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({ username: '', password: '' });
  const [showModal, setShowModal] = useState(false);
  const { username, password } = formValues;
  const navigate = useNavigate();

  useEffect(() => {
    if (error || loading) setShowModal(true);
    else setShowModal(false);
  }, [error, loading]);

  useEffect(() => {
    if (agent) navigate('/search');
  }, [agent, navigate]);


  const handlerSubmit = (event) => {
    event.preventDefault();
    dispatch(login(username, password));
  };

  const handlerFormValues = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  }

  return (
    <LoginView
      onSubmit={handlerSubmit}
      password={password}
      username={username}
      setFormValues={handlerFormValues}
      showModal={showModal}
      setShowModal={setShowModal}
      error={error}
      loading={loading}
    />
  )
}
