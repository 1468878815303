import moment from 'moment';

export const downloadFile = (url, fileName) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const createUrlBlobFromBase64 = async (file64) => {
  const mimetype = file64.split(',')[0].split(';')[0].split(':')[1];
  const response64 = await fetch(file64);
  const blob = await response64.blob();
  const urlBlob = window.URL.createObjectURL(new Blob([blob], { type: mimetype }));
  return urlBlob;
};

export const formatFileName = (filenName) => {
  const ns = filenName.split('_');
  const hour = moment(ns[2], 'HHmmss');

  const newName = ns[1] + '_' + hour.format('HH:mm:ss') + '.' + ns[3].split('.')[1];
  return newName;
}

export const sortFilesByDate = (files) => {
  return files.sort((a, b) => {
    const nameA = a.nombreDoc.split('_');
    const dateA = moment(nameA[1] + ' ' + nameA[2], 'DDMMYYYY HHmmss');
    const nameB = b.nombreDoc.split('_');
    const dateB = moment(nameB[1] + ' ' + nameB[2], 'DDMMYYYY HHmmss');
    return dateB - dateA;
  });
}