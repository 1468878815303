import React from 'react';
import { Input } from '../basics/input/Input';
import { Submit } from '../basics/buttons/Submit';
import './SearchStyle.css';
import { Spinner } from '../basics/Spinner';
import { FaFile, FaEye, FaFileDownload, FaSignOutAlt } from 'react-icons/fa';
import { formatFileName } from '../../utils/funtionsFiles';

export const SearchFilesView = ({
  setFormValues,
  selectDocs,
  formValues,
  onSubmit,
  files,
  loading,
  error,
  viewDoc,
  downDoc,
  logOut,
}) => {
  const { typeDoc, identification, initDate, endDate } = formValues;

  return (
    <div className="container py-2 d-flex flex-column text-center align-items-center main-background vh-100 pb-6">
      {/* <div className="space"></div> */}
      {/* <div className="space"></div> */}
      {/* <div className="space"></div> */}
      <div className="container d-flex justify-content-end ">
        <div className="clickable d-flex" onClick={logOut}>
          <FaSignOutAlt style={{ transform: 'rotate(180deg)' }} size={25} className="me-2" />
          salir
        </div>
      </div>

      <div className="scroll-hidden pt-5 space-top">
        <div className="space"></div>
        <div className="container border border-primary rounded text-center box_shadow">
          <h3 className="txt-black">
            CONSULTA DE DOCUMENTOS ESCANEADOS POR AFILIADOS EN KIOSCOS DE CENTROS MÉDICOS PROPIOS
          </h3>
        </div>

        <form className="container d-flex flex-wrap flex-column" onSubmit={onSubmit}>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="form-floating field-form-float">
              {typeDoc !== 'Tipo de identificación' && <label htmlFor="typeDoc">Tipo de identificación</label>}
              <select
                id="typeDoc"
                name="typeDoc"
                className="form-select form-input"
                aria-label="select type document"
                onChange={setFormValues}
                defaultValue=""
                required
              >
                {selectDocs.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-floating field-form-float mb-3">
              <Input
                id="identification"
                name="identification"
                onChange={setFormValues}
                value={identification}
                label="Número de identificación"
              />
            </div>

            <div className="form-floating field-form-float mb-3">
              <Input id="initDate" name="initDate" onChange={setFormValues} value={initDate} type="date" required={false} />
              <label htmlFor="initDate">Fecha inicio</label>
            </div>

            <div className="form-floating field-form-float mb-3">
              <Input id="endDate" name="endDate" onChange={setFormValues} value={endDate} type="date" required={false} />
              <label htmlFor="endDate">Fecha Fin</label>
            </div>
          </div>

          <Submit value="CONSULTAR" />
        </form>

        {(loading || error) && (
          <div className="container flex-column justify-content-around">
            {loading && (
              <div className="container p-3">
                <h1 className="txt-black">Espere</h1>
                <Spinner />
              </div>
            )}
            {error && (
              <div className="container p-3">
                <h3 className="txt-black">{error}</h3>
              </div>
            )}
          </div>
        )}
        {(files && files.length > 0) && (
          <div className="container border border-primary m-3 p-2 rounded bg-green-ligth">
            <div className="scroll-hidden">
              <div className="grid-title bg-green m-1 p-1 font-white">
                <div></div>
                <div>Documentos</div>
                <div>Visualizar</div>
                <div>Descargar</div>
              </div>

              {files.map((file) => (
                <div key={file.nombreDoc} className="grid-row">
                  <FaFile size="1.3em" />
                  <div className="grid-item">
                    {' '}
                    {formatFileName(file.nombreDoc)}
                  </div>

                  <FaEye size="1.3em" className="clickable" onClick={() => viewDoc(file.documento)} />
                  <FaFileDownload
                    size="1.3em"
                    className="clickable"
                    onClick={() => downDoc(file.documento, file.nombreDoc)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
