import { authTypes } from '../types/authTypes';

const initialState = {
  loading: false,
  error: null,
  agent: null,
  tokens: {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null
  }
}

export const authReducer = (state = initialState, action) => {

  switch (action.type) {

    case authTypes.LOGIN:
      return {
        ...state,
        agent: action.payload.agent,
        tokens: action.payload.tokens,
      }

    case authTypes.LOGOUT:
      return {}

    case authTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }

    case authTypes.REMOVE_ERROR:
      return {
        ...state,
        error: null,
      }

    case authTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }

    case authTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }

    case authTypes.REFRESH_TOKEN:
      return {
        ...state,
        tokens: action.payload.tokens
      }

    case authTypes.GET_USER_FILES:
      return {
        ...state,
        userFiles: action.payload.userFiles
      }

    case authTypes.SELECT_FILE:
      return {
        ...state,
        fileSelected: action.payload.fileSelected
      }

    case authTypes.CLEAR_FILE:
      return {
        ...state,
        fileSelected: null
      }

    default:
      return state;
  }
}
