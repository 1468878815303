import './input.css'

export const Input = ({ label, placeholder, name, value, onChange, className, type = 'text', required = true }) => {
  return (
    <>
      {!!label && <label className="">{label}</label>}
      <input
        type={type}
        className={'form-input ' + className}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
        required={required}
      />
    </>
  )
}
